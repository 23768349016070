import * as THREE from 'three'
import React, { useRef, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ texture }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/USOA-Collaboration-Can_Warby.gltf')

  useEffect(() => {
    if (texture) {
      const textureLoader = new THREE.TextureLoader()
      const newTexture = textureLoader.load(texture)

      newTexture.flipY = materials.PBR.map.flipY
      newTexture.wrapS = materials.PBR.map.wrapS
      newTexture.wrapT = materials.PBR.map.wrapT
      newTexture.encoding = materials.PBR.map.encoding
      newTexture.minFilter = materials.PBR.map.minFilter
      newTexture.magFilter = materials.PBR.map.magFilter
      materials.PBR.map = newTexture

      materials.PBR.needsUpdate = true
    }
  }, [texture, materials])

  return (
    <group ref={group} dispose={null}>
      <group rotation={[0, 0, 0]} position={[0, 0, 0]} scale={[0.42, 0.42, 0.42]}>
        <group rotation={[0, -2.75, 0]}>
          <group position={[0, 23.71, 0]}>
            <group position={[0, 4.42, 0]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Clip.geometry}
                material={nodes.Clip.material}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
              />
            </group>
            <group position={[0, 4.55, 0]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Nippel.geometry}
                material={nodes.Nippel.material}
              />
            </group>
            <group position={[0, -8.97, 0]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Can_Top.geometry}
                material={nodes.Can_Top.material}
                position={[0, 13.52, 0]}
                rotation={[0, 0, 0]}
              />
            </group>
          </group>
          <group position={[0, 28.26, 0]}>
            <group position={[0, -13.52, 0]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes['Can_Mid-PBR1'].geometry}
                material={nodes['Can_Mid-PBR1'].material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes['Can_Mid-PBR'].geometry}
                material={materials.PBR}
              />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/USOA-Collaboration-Can_Warby.gltf')
